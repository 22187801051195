// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elerhetoseg-index-de-js": () => import("./../../../src/pages/elerhetoseg/index.de.js" /* webpackChunkName: "component---src-pages-elerhetoseg-index-de-js" */),
  "component---src-pages-elerhetoseg-index-en-js": () => import("./../../../src/pages/elerhetoseg/index.en.js" /* webpackChunkName: "component---src-pages-elerhetoseg-index-en-js" */),
  "component---src-pages-elerhetoseg-index-js": () => import("./../../../src/pages/elerhetoseg/index.js" /* webpackChunkName: "component---src-pages-elerhetoseg-index-js" */),
  "component---src-pages-gyik-index-js": () => import("./../../../src/pages/gyik/index.js" /* webpackChunkName: "component---src-pages-gyik-index-js" */),
  "component---src-pages-hirek-index-js": () => import("./../../../src/pages/hirek/index.js" /* webpackChunkName: "component---src-pages-hirek-index-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rolunk-media-index-js": () => import("./../../../src/pages/rolunk/media/index.js" /* webpackChunkName: "component---src-pages-rolunk-media-index-js" */),
  "component---src-pages-rolunk-tagok-index-js": () => import("./../../../src/pages/rolunk/tagok/index.js" /* webpackChunkName: "component---src-pages-rolunk-tagok-index-js" */),
  "component---src-pages-rolunk-videok-index-js": () => import("./../../../src/pages/rolunk/videok/index.js" /* webpackChunkName: "component---src-pages-rolunk-videok-index-js" */),
  "component---src-pages-tudastar-index-js": () => import("./../../../src/pages/tudastar/index.js" /* webpackChunkName: "component---src-pages-tudastar-index-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/simple-page.js" /* webpackChunkName: "component---src-templates-simple-page-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

